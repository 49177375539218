import { AuthenticationRoutes } from "../modules/authentication/AuthenticationRoutes";
import { DashboardRoutes } from "../modules/dashboard/DashboardRoutes";
import { SettingsRoutes } from "../modules/settingss/SettingsRoutes";
import { PractitionerRoutes } from "../modules/practitioners/PractitionerRoutes";
import { UsersRoutes } from "../modules/users/UsersRoutes";
import { RecoverAccountRoutes } from "../modules/recoverAccount/RecoverAccountRoutes";

export const routes = [
    ... AuthenticationRoutes,
    ... DashboardRoutes,
    ... SettingsRoutes,
    ... PractitionerRoutes,
    ... UsersRoutes,
    ... RecoverAccountRoutes
];