import React from "react";

const PractitionerComponent = React.lazy(() => import("./PractitionerDetail/PractitionerDetail"));
const PractitionerDetails = React.lazy(() => import("./pracititionerDetails/practitioner-details"));
// const ProfessionalsListComponent = React.lazy(() => import('../../components/ProfessionalsList/ProfessionalsList'));
export const PractitionerRoutes = [
    {
        path: "/practitioner/:practitioner",
        component: PractitionerComponent,
        key: "root",
        exact: true,
        routes: [],
    },
    {
        path: "/practitioner-details/:practitioner",
        component: PractitionerDetails,
        key: "root",
        exact: true,
        routes: [],
    }
];
